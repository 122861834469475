<template>
	<div>
		<v-list two-line>
			<v-subheader>{{ $t("selectUnit.txtTitle") }}</v-subheader>
			<v-list-item-group v-model="selected" active-class="purple--text">
				<template v-for="(item, index) in transportUnits">
					<v-list-item :key="item.name">
						<template v-slot:default="{ active }">
							<v-list-item-content>
								<v-list-item-title
									v-text="item.name"
								></v-list-item-title>

								<v-list-item-subtitle class="text--primary"
									>{{ item.unitBrand }}
									{{ item.unitModel }}</v-list-item-subtitle
								>

								<v-list-item-subtitle>{{
									item.plateNumber
								}}</v-list-item-subtitle>
							</v-list-item-content>

							<v-list-item-action>
								<v-icon v-if="!active" color="grey lighten-1">
									mdi-checkbox-blank-circle-outline
								</v-icon>

								<v-icon v-else color="yellow darken-3">
									mdi-checkbox-marked-circle
								</v-icon>
							</v-list-item-action>
						</template>
					</v-list-item>

					<v-divider
						v-if="index < transportUnits.length - 1"
						:key="index"
					></v-divider>
				</template>
			</v-list-item-group>
		</v-list>
		<div class="ml-2 mr-2 mt-4" v-if="transportUnits.length > 0">
			<v-btn
				block
				tile
				dark
				@click.prevent="saveChange"
				:disabled="selected === null"
				color="deep-purple lighten-2"
			>
				{{ $t("profile.btnSave") }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
	name: "SelectUnit",
	data: () => ({
		selected: null,
		transportUnits: [],
	}),
	computed: {
		...mapState("auth", {
			user: (state) => state.user,
		}),
	},
	methods: {
		...mapActions("ui", {
			disabledNavigation: "disabledNavigation",
			activeBackBtn: "activeBackBtn",
			showProgress: "showProgress",
			hideProgress: "hideProgress",
		}),
		...mapMutations("auth", {
			setUnitTransport: "setUnitTransport",
		}),
		saveChange() {
			this.showProgress();
			const transportUnit = this.transportUnits[this.selected]; //
			this.$axios
				.post(`/transport-units/${transportUnit.id}/assign-driver`)
				.then((res) => {
					// Ocultar notificacion
					this.hideProgress();

					// Guardar el transporte
					this.setUnitTransport(res.data);

					// notificar
					this.$store.commit(
						"ui/openSnackbar",
						this.$t("selectUnit.txtNotification")
					);

					// Redirigir al lugar correcto
					this.$router.push({ name: "Home" });
				});
		},
	},
	mounted() {
		this.showProgress();
		this.$axios
			.get(`/transport-units`, { params: { limit: 250 } })
			.then((res) => {
				this.transportUnits = res.data.rows;
				if (this.user.currentTransportUnit) {
					this.transportUnits.forEach((unit, index) => {
						if (unit.id === this.user.currentTransportUnit.id) {
							this.selected = index;
                            this.activeBackBtn();
						}
					});
				}
				this.hideProgress();
			});
		// =>
		this.disabledNavigation();
	},
};
</script>
